<template>
<div>
  <b-container class="py-5 text-white">
    <b-row class="py-5">
      <b-col>
        <h1 class="py-2">Report generation</h1>
        <h4 class="py-2">Generate reports from CSV files</h4>
        <p>Facilities production of Word reports from Excel files</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-for="step in steps" :key="'step' + step.id" class="mb-4">
          <file-loader :set="step" />
        </div>
      </b-col>
    </b-row>
    <b-row v-if="true">
      <b-col>
        <b-button class="mr-4" variant="light" @click="showMain">Show</b-button>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import config from './config.js'
import FileLoader from '@/components/FileLoader.vue'

export default {
  components: {
    FileLoader
  },
  created: async function () {
  },
  data () {
    return {
      steps: config.files
    }
  },
  methods: {
    showMain: function () {
      this.$router.push({ name: 'AppReportingMain' })
    }
  }
}
</script>
<style lang="scss" scoped>
a, .nav-link {
  color: white;
}
a:hover, .nav-link:hover {
  color: white;
}
</style>
