<template>
  <b-container>
    <b-row v-if="step.data" class="mb-2">
      <b-col>
        <h3>{{set.name}}</h3>
        <input v-if="!step.loadedData" type="file" :id="id"  @change="dataFileRegister()" />
        <b-button variant="light" v-if="file && !step.loadedData" @click="dataFileStream()">Load</b-button>
        <div v-if="set.columns">
          required columns: <span v-for="column in set.columns" :key="column">{{column}}, </span>
        </div>
        <div class="my-4" v-if="step.loadingData">
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <div v-if="step.loadingData || step.loadedData">
          processed rows of data: {{lines}}
        </div>
        <div v-if="step.loadedData">
          <b-button variant="light" v-if="file" @click="dataFileReload()">Reload</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="step.loadingData" class="my-5 py-5">
      <b-col>
        <h3>Loading data</h3>
        <div><b-spinner label="Spinning"></b-spinner></div>
        <h4>processed rows of data: {{lines}}</h4>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import async from 'async'
import parse from 'csv-parse'

export default {
  props: ['set'],
  created: async function () {
    this.id = 'frmcloudReportingApp' + this.set.id
    const recordsRaw = localStorage.getItem(this.id)
    if (recordsRaw) {
      this.recordsRaw = JSON.parse(recordsRaw)
      this.lines = this.recordsRaw.length
      this.step.data = true
      this.step.loadingData = false
      this.step.loadedData = true
      this.file = true
    } else {
      this.step.data = true
      this.step.loadingData = false
      this.step.loadedData = false
    }
    this.parser = parse({
      columns: true,
      delimiter: ','
    })
    this.parser.on('readable', () => {
      let record
      while ((record = this.parser.read()) !== null) {
        this.recordsRaw.push(record)
      }
    })
  },
  data () {
    return {
      completed: false,
      file: false,
      id: null,
      lines: 0,
      parser: null,
      reader: null,
      step: {
        data: true,
        loadingData: false,
        loadedData: false
      },
      stream: null,
      recordsRaw: []
    }
  },
  methods: {
    dataFileRegister: function () {
      this.file = document.getElementById(this.id).files[0]
    },
    dataFileReload: function () {
      localStorage.removeItem(this.id)
      this.recordsRaw = []
      this.completed = false
      this.lines = 0
      this.step.data = true
      this.step.loadingData = false
      this.step.loadedData = false
      this.file = null
      this.stream = null
      this.reader = null
    },
    dataFileStream: async function () {
      this.step.data = false
      this.step.loadingData = true
      this.stream = await this.file.stream()
      this.reader = await this.stream.getReader()
      await async.until(async.asyncify(this.testStream), async.asyncify(this.processChunk))
      this.step.loadingData = false
      this.step.loadedData = true
      this.step.data = true
      this.lines = this.recordsRaw.length
      const recordsRaw = this.recordsRaw
      localStorage.setItem(this.id, JSON.stringify(recordsRaw))
      this.stream = null
      this.reader = null
    },
    processChunk: async function () {
      try {
        const chunk = await this.reader.read()
        if (chunk.done) {
          this.completed = true
          return
        }
        this.parser.write(chunk.value)
        this.lines = this.recordsRaw.length
      } catch (e) {
        console.log(e)
      }
    },
    testStream: function () {
      return this.completed
    }
  }
}
</script>
<style lang="scss" scoped>
a, .nav-link {
  color: white;
}
a:hover, .nav-link:hover {
  color: white;
}
</style>
